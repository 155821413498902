import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  getItem: async (key: string) => {
    return await http.get(`${getApiUrl()}/api/setting/${key}`);
  },
};

export default {
  ...actions,
};
